import avatar from "@/assets/images/default-user-avatar.gif";
import cloudFlareCdnCgiLoader from "@/components/common/cloudFlareCdnCgiLoader";
import { EnumRatioType, s3FolderTypes } from "@/components/constants/layout";
import { isEmpty } from "lodash";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

function HeroNfts({ heroData }) {
  const [nftData, setNftData] = useState([]);
  const [isFetchingHeroSectionData, setIsFetchingHeroSectionData] =
    useState(false);

  useEffect(() => {
    if (!isEmpty(heroData)) {
      setIsFetchingHeroSectionData(false);
      setNftData(heroData);
    }
  }, [heroData, nftData, isFetchingHeroSectionData]);

  return (
    <>
      <div className="chosen">
        {((nftData && nftData) || []).map((item) => {
          return (
            <Link
              key={item?.Id}
              href={`/nfts/details/${item?.Slug}`}
              data-link={`/nfts/details/${item?.Slug}`}
              className={`item_card chosen__card ${
                item?.RatioType === EnumRatioType.Portrait
                  ? "portrait"
                  : "landscape"
              }`}
            >
              <div className="chosen__preview">
                <Image
                  loader={cloudFlareCdnCgiLoader}
                  src={`${process.env.NEXT_PUBLIC_S3_HOST_NAME}/${s3FolderTypes.S3NftFolder}/${item?.ImageUrl}`}
                  alt={`${item?.Name}-Seed Nft`}
                  width={item?.RatioType === EnumRatioType.Portrait ? 244 : 374}
                  height={250}
                  quality={70}
                  loading="eager"
                  // style={{
                  //   objectFit: "cover",
                  //   height: "100%",
                  // }} // cover, contain, none
                  // className={loaded ? "unblur" : "blacks"}
                  // onLoad={() => setLoaded(true)}
                />

                <button
                  className="card2__creator"
                  data-link={`/artist/${item?.Creator.UserName}`}
                >
                  <div className="card2__avatars">
                    {item?.Creator.ProfileImage !== null ? (
                      <Image
                        loader={cloudFlareCdnCgiLoader}
                        src={`${process.env.NEXT_PUBLIC_S3_HOST_NAME}/${s3FolderTypes.S3ProfileFolder}/${item?.Creator.ProfileImage}`}
                        width={48}
                        quality={75}
                        height={48}
                        loading="lazy"
                        alt="user-profile"
                        style={{
                          objectFit: "cover",
                          height: "100%",
                        }} // cover, contain, none
                      />
                    ) : (
                      <Image
                        src={avatar}
                        width={48}
                        height={48}
                        loading="lazy"
                        alt="Avatar"
                      />
                    )}
                  </div>
                  <span className="creator__name">
                    {item?.Creator.DisplayName !== null
                      ? item?.Creator.DisplayName
                      : item?.Creator.FirstName !== null &&
                        item?.Creator.LastName
                      ? `${item?.Creator.FirstName} ${item?.Creator.LastName}`
                      : item?.Creator.UserName}
                  </span>
                </button>
              </div>
              <div className="chosen__title">{item?.Name}</div>
            </Link>
          );
        })}
      </div>
    </>
  );
}

export default HeroNfts;
