import logoSeedWhite from "@/assets/images/Seed-logo-main - white.svg";
import logoSeedDark from "@/assets/images/Seed-logo-main.svg";
import Image from "next/image";

import { createSelector } from "@reduxjs/toolkit";
import Link from "next/link";
import React from "react";
import HeroNfts from "./hero/heroNfts";
import MintedCount from "./hero/mintedCount";

const selectHeroSection = (state) => state.HeroSection;

export const selectLoadingHeroNfts = createSelector(
  [selectHeroSection],
  (heroSection) => heroSection.loadingHeroNfts
);

function HeroSection({
  heroData,
  children,
  differentButton = false,
  buttonElements,
}) {
  return (
    <>
      <div className="hero-center">
        <div className="hero__row">
          <div className="hero__col">
            <div className="hero__site_logo">
              <Image
                width={420}
                height={83}
                className="some-icon"
                src={logoSeedDark}
                alt="seed logo dark"
              />

              <Image
                width={420}
                height={83}
                className="some-icon-dark"
                src={logoSeedWhite}
                alt="seed logo white"
              />
            </div>

            {/* <h1 className="hero__title h1">
              Photography <br /> <span style={{ color: "#8e793e" }}>NFT </span>
              Platform
            </h1> */}

            {children}

            {/* //TODO main buttons */}
            {/* <DiscoverBtns /> */}

            {!differentButton ? (
              <div className="hero__navigation">
                <Link
                  className="button main__button"
                  href="https://www.mexc.com/exchange/SEED_USDT"
                  target="_blank"
                >
                  Buy Now
                </Link>

                <Link
                  className="button main__button"
                  href="https://pancakeswap.finance/swap?inputCurrency=0x6730f7a6bbb7b9c8e60843948f7feb4b6a17b7f7&outputCurrency=0x55d398326f99059ff775485246999027b3197955"
                  target="_blank"
                >
                  Swap Now
                </Link>
              </div>
            ) : null}

            <MintedCount />
          </div>

          <div className="hero__col">
            <HeroNfts heroData={heroData} />
          </div>

          {/* --------- */}
        </div>
      </div>
    </>
  );
}

export default React.memo(HeroSection);
