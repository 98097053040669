const normalizeSrc = (src) => {
  return src.startsWith("/") ? src.slice(1) : src;
};

export function cloudFlareCdnCgiLoader({ src, width, quality }) {
  const params = [`width=${width}`, `quality=${quality || 75}`, "format=webp"];

  return `${process.env.NEXT_PUBLIC_MEDIA_BASE_URL}/cdn-cgi/image/${params.join(
    ","
  )}/${normalizeSrc(src)}`;
}

export default cloudFlareCdnCgiLoader;
