import iconBinance from "@/assets/images/chains/icon-Binance.svg";
import iconEthereum from "@/assets/images/chains/icon-Ethereum.svg";
import iconPolygon from "@/assets/images/chains/icon-Polygon.svg";
import iconSui from "@/assets/images/chains/sui-icon.svg";
import { mintedNftCountData } from "@/store/actions";
import { createSelector } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import Image from "next/image";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const selectHeroSection = (state) => state.HeroSection;

export const selectMintedCount = createSelector(
  [selectHeroSection],
  (heroSection) => heroSection.mintedCount
);

function MintedCount() {
  const dispatch = useDispatch();
  const [nftMintedCount, setNftMintedCount] = useState(null);

  const mintedCount = useSelector(selectMintedCount);

  useEffect(() => {
    if (isEmpty(mintedCount)) {
      setTimeout(() => {
        dispatch(mintedNftCountData());
      }, 500);
    }

    if (!isEmpty(mintedCount)) {
      setNftMintedCount(mintedCount);
      // console.log("mintedCount", nftMintedCount);
    }
  }, [dispatch, mintedCount, nftMintedCount]);

  const memoizedCameraIcon = useMemo(
    () => (
      <>
        <div>
          <div className="some-icon">
            <lord-icon
              src="/lord-icon/camera.json"
              trigger="loop-on-hover"
              colors="primary:#000000,secondary:#8e793e"
              delay="1000"
              style={{ width: "50px", height: "50px" }}
            ></lord-icon>
          </div>
          <div className="some-icon-dark">
            <lord-icon
              src="/lord-icon/camera.json"
              trigger="loop-on-hover"
              colors="primary:#ffffff,secondary:#8e793e"
              delay="1000"
              style={{ width: "50px", height: "50px" }}
            ></lord-icon>
          </div>
        </div>
      </>
    ),
    []
  );

  return (
    <>
      <div className="hero__stage">
        {memoizedCameraIcon}
        <span className="hero__stage_count">
          {/* {console.log("nftMintedCount", mintedCount)} */}
          <b>{mintedCount && mintedCount}</b>
        </span>
        artworks minted
        <div className="hero__stage_chain">
          <Image
            src={iconBinance}
            alt="bnb-icon"
            width={25}
            height={25}
            loading="lazy"
          />
          <Image
            src={iconEthereum}
            alt="eth-icon"
            width={25}
            height={25}
            loading="lazy"
          />
          <Image
            src={iconPolygon}
            alt="matic-icon"
            width={25}
            height={25}
            loading="lazy"
          />

          <Image
            src={iconSui}
            alt="matic-icon"
            width={20}
            height={20}
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
}

export default React.memo(MintedCount);
